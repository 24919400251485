<template>
    <div class="grid gap-4 grid-cols-1" :class="`md:grid-cols-${gridCols}`">
        <template v-for="category in categories" :key="category.slug">
            <ImageCard
                :title="category.name"
                :description="category.description"
                :background-image="category.cover_responsive_images"
                :show-cta="true"
                class="max-w-full">
                <template #ctaButton>
                    <ButtonOutline
                        :url="route('categories.show', {categories: category.full_slug})"
                        :text="$t('frontend.discover_more')" />
                </template>
            </ImageCard>
        </template>
    </div>
</template>
<script setup>
import {ButtonOutline} from '@/Components/Frontend/Typography';
import {ImageCard} from '@/Components/Typography';

// import {ProductCategoryCard} from '@/Components/Frontend/Typography';

const props = defineProps({
    categories: {
        type: Array,
        required: true,
    },
});

const gridCols = props.categories.length !== 4 ? 3 : 2;
</script>
